
  body::-webkit-scrollbar {
    display: none;
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }


.jumbotron {
  position: relative;
  width: 100%;
  @media screen and (min-width: 800px) {
  height: calc(100vh - 12vh); // Adjust this based on your navbar height

  }
  overflow: hidden;

  .jumbotron-slide {
    width: 100%;
    height: 100%;

    .jumbotron-slide-pic {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .jumbotron-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: white;
    }
  }

  .slick-dots {
    bottom: 10px;
  }

  .slick-prev,
  .slick-next {
    z-index: 1;
  }
}

.static-elements {
  position: absolute;

  z-index: 2;

}

.scroll-button {
  position: absolute;
  bottom: 32px;
  right: 64px;
  z-index: 2;
  background-color: transparent;
  border: 1px solid gainsboro;
  color: gainsboro;
  padding: 1.5rem;
  border-radius: 9999px;
  cursor: pointer;
  &:hover {
    background-color: rgba($color: #000000, $alpha: .1);
    color: white;
    border-color: white;
  }
  @media screen and (max-width: 800px) {
    //do Smth
    display: none;
  }
}
.metallic-button {
  outline: none;
  color: #000;
  background: linear-gradient(
    45deg,
    #999 5%,
    #fff 10%,
    #ccc 30%,
    #ddd 50%,
    #ccc 70%,
    #fff 80%,
    #999 95%
  );
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.metallic-button:hover {
  transform: translateY(-2px);
}
