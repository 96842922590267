@keyframes borderPulse {
    0% {
        border-color: transparent;
    }
    50% {
        border-color: blue;
    }
    100% {
        border-color: transparent;
    }
}

.loading-border {
    animation: borderPulse 1s infinite;
}
.dolu{
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}
.bos{
    align-items: center;
    justify-content: center;

}

.trapezoid {
    margin: 10px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 1;
  }
  
  .trapezoid-1{

    width: 100%;
    background: #fff;
    background-image: url("../../public/diagonal.png");
    background-size: cover;
    border-radius: .5rem;
    margin-bottom: 1rem;

    @media only screen and (min-width: 1000px){
        height: 400px;
        clip-path: polygon(0 0, 100% 0, 50% 100%, 0 100%);
        position: relative; /* Required for absolute positioning of <p> tag */
        transform: translateX(5vw);
        border: 2px solid #505050;
        margin-bottom: 0;
    }

  }
  .textFieldr{
    background-color: rgba($color: #fff, $alpha: .5);
    @media only screen and (min-width: 1000px){
        width: 70%;
    }

  }
  .textFieldl{
    background-color: rgba($color: #fff, $alpha: .5);
    @media only screen and (min-width: 1000px){
        width: 70%;
    }
  }
  
  .trapezoid-1 p{
    color: black !important;
    text-align: left
  }
  .trapezoid-2 p {
    color: black !important;
    text-align: right;
  }
  
  .trapezoid-2 {
    width: 100%;
    background: #fff;
    background-image: url("../../public/diagonalR.png");
        background-size: cover;
        border-radius: .5rem;
        
    @media only screen and (min-width: 1000px){
        height: 400px;
        clip-path: polygon(50% 0, 100% 0, 100% 100%, 0 100%);
        position: relative; /* Required for absolute positioning of <p> tag */
        transform: translateX(-5vw);
        border: 2px solid #505050;
        align-items: flex-end;
        text-align: right;
        justify-content: flex-end;
        display: flex;
    }

  }

  .trapezoid-1,.trapezoid-2{
    padding: 1rem;
  }
  
  .circles{
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    gap:2rem;
    color: #fff;
    .circle-1-outer,.circle-2-outer{
      flex: 1;
      position: relative;
    }
    .circle-1,.circle-2{
      padding: 4rem;
      @media only screen and (max-width: 1000px){
        padding: 1rem;
    }

    }
    .circle-1-outer{
      //background-color: #D2D78B;
      background-color: #f5f5f5;
      padding: 2rem .5rem .2rem 1rem;
      
    }
    .circle-1{
      //background-color: #62A182;
      background-color: #1e2d47;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .icon-holder{
        position: absolute;
        bottom: -12px;
        left: -12px;
        padding: 1rem;
        background-color: #1e2d47;

        
      }
    }
    .circle-2-outer{
      background-color: #FFDA88;
      background-color: #1e2d47;
      padding: .5rem 2rem 1rem .2rem;

      
    }
    .circle-2{
      background-color: #DA8B19;
      background-color: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      color: #1e2d47 !important;
      .icon-holder{
        position: absolute;
        top: -12px;
        right: -12px;
        padding: 1rem;
        background-color: #FFD14F;
        background-color: #f5f5f5;
        
      }

      
    }
    .icon{
      width: 64px;
      height: 64px;
      @media only screen and (max-width: 1000px){
        width: 32px;
        height: 32px;
           }
    }


  }
  
  @keyframes moveCircle {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-15px);
    }
    100% {
      transform: translateY(0);
    }
  }